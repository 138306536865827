import httpManager from "../axios/httpManager";

export function getLoginLogList (search, pageNum, pageSize) {
    let url = '/log/login'+'?pageNum=' + pageNum + '&pageSize=' + pageSize;
    return httpManager.post(url, search);
}

export function getRepeateLoginList (search, pageNum, pageSize) {
    let url = '/log/repeatelogin'+'?pageNum=' + pageNum + '&pageSize=' + pageSize;
    return httpManager.post(url, search);
}
export function repeateAlramChecked () {
    let url = '/log/repeate_alram_checked'+'?t=' + new Date().getMilliseconds();
    return httpManager.get(url);
}


export function getLoginEventList  (search, pageNum, pageSize) {
    let url = '/log/loginEvent'+'?pageNum=' + pageNum + '&pageSize=' + pageSize;
    return httpManager.post(url, search);
}